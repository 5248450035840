<template>
    <div>
        <div class="filter-wrap flex-between">
            <div class="flex-start search-filter-wrap">
                <el-input
                        v-model="listQuery.SearchStr"
                        width="180"
                        placeholder="请输入内容"
                        clearable
                        @clear="handleFilter"
                />
                <el-button
                        size="small"
                        type="primary"
                        icon="el-icon-search"
                        @click="handleFilter"
                >
                    搜索
                </el-button>
            </div>
            <el-button
                    v-show="savePerm"
                    size="small"
                    type="primary"
                    icon="el-icon-plus"
                    @click="saveDialogShow(1)"
            >
                新增
            </el-button>
        </div>
        <el-table
                v-loading="loading"
                fit
                border
                highlight-current-row
                :data="tableData"
                stripe
                style="width: 100%"
        >
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="name"
                    label="名称"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="dataSourceType"
                    label="数据来源"
            >
                <template slot-scope="{row}">
                    {{dataSourceTypeObj[row.dataSourceType]}}
                </template>
            </el-table-column>
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="syncMethod"
                    label="同步方式"
            >
                <template slot-scope="{row}">
                    {{syncMethodObj[row.syncMethod]}}
                </template>
            </el-table-column>
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="storageMethod"
                    label="数据入库方式"
            >
                <template slot-scope="{row}">
                    {{putStorageObj[row.storageMethod]}}
                </template>
            </el-table-column>
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="url"
                    label="对接地址"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="account"
                    label="账号"
            />
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="labelName"
                    label="同步时间"
            >
                <template slot-scope="{row}">
                    {{row.syncBeginTime}} - {{row.syncEndTime}}
                </template>
            </el-table-column>
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="syncFrequency"
                    label="同步频率"
            >
                <template slot-scope="{row}">
                   {{row.syncFrequency}} {{syncFrequencyTypeObj[row.syncFrequencyType]}}
                </template>
            </el-table-column>
            <el-table-column
                    :show-overflow-tooltip="true"
                    prop="status"
                    label="状态"
            >
                <template slot-scope="{row}">
                    <!--<b-badge-->
                            <!--class="d-flex justify-content-center"-->
                            <!--style="align-items: center"-->
                            <!--pill-->
                            <!--:variant="statusColorObj[row.status]"-->
                    <!--&gt;-->
                        <!--{{ statusObj[row.status] }}-->
                    <!--</b-badge>-->
                    <el-switch
                            @change="updateDataFindStatus(row.id,row.status)"
                            :active-value="1"
                            :inactive-value="2"
                            v-model="row.status"
                            active-color="rgb(19, 206, 102)"
                            inactive-color="rgb(255, 73, 73)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                    label="操作"
                    width="120px"
            >
                <template slot-scope="{row}">
                    <!--<el-link-->
                            <!--class="mr10"-->
                            <!--:underline="false"-->
                            <!--type="primary"-->
                            <!--@click="updateDataFindStatus(row.id,row.status)"-->
                    <!--&gt;-->
                        <!--<el-tooltip-->
                                <!--:content="row.status ==1 ? '停止执行' : '立即执行'"-->
                                <!--placement="top"-->
                                <!--effect="light"-->
                        <!--&gt;-->
                            <!--<i-->
                                    <!--:class="row.status ==1 ? 'icon-tingyong' : 'icon-qiyong'"-->
                                    <!--class="iconfont"-->
                            <!--/>-->
                        <!--</el-tooltip>-->
                    <!--</el-link>-->
                    <el-link
                            v-if="readPerm"
                            :underline="false"
                            class="mr10"
                            @click="toDetail(row)"
                            type="primary"
                    >
                        <el-tooltip
                                content="详情"
                                placement="top"
                                effect="light"
                        >
                            <i class="el-icon-document" />
                        </el-tooltip>
                    </el-link>
                    <el-link
                            v-if="updatePerm"
                            class="mr10"
                            type="primary"
                            @click="saveDialogShow(2,row)"
                    >
                        <el-tooltip
                                content="编辑"
                                placement="top"
                                effect="light"
                        >
                            <i class="el-icon-edit" />
                        </el-tooltip>
                    </el-link>
                    <el-link
                            v-if="delPerm"
                            type="primary"
                            @click="del(row)"
                    >
                        <el-tooltip
                                content="删除"
                                placement="top"
                                effect="light"
                        >
                            <i class="el-icon-delete" />
                        </el-tooltip>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <PageQuery  v-show="total>0"
                    :total="total"
                    @pagination="getList" :cacheName="'listQuery4'"  :updateState="'UPDATE_LISTQUERY4'" :listQuery.sync="listQuery" :initListQuery.sync="initListQuery" />
    </div>
</template>

<script>
  import pagination from '@core/components/pagination/Pagination.vue'
  import { DeleteLabelById, GetLabelPage } from '@/api/system/privacy/privacy'
  import { GetDataFindListByPage,UpdateDataFindStatus,DeleteDataFindByIds } from '@/api/dataFound/dataFound'
  import { error, findBtnPerm, success,getBtnPerms , verifyBtnPerm } from '@core/utils/utils'
  import { UpdateStatus } from '@/api/system/user/user'

  export default {
    components: {
      pagination,
    },

    data() {
      const listQuery = {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      }
      return {
        syncFrequencyTypeObj: {
          1: '小时',
          2: '分钟',
        },
        savePerm: findBtnPerm('data-find-2'),
        updatePerm: findBtnPerm('data-find-3'),
        delPerm: findBtnPerm('data-find-4'),
        runPerm: findBtnPerm('data-find-5'),
        readPerm: findBtnPerm('data-find-6'),
        detailShow: false,
        loading: false,
        statusObj: {
          2: '停用',
          1: '启用',
        },
        statusColorObj: {
          2: 'warning',
          1: 'success',
        },
        dataSourceTypeObj: {
          1: '昂楷数据扫描器'
        },
        syncMethodObj: {
          1: '接口拉取',
        },
        putStorageObj: {
          1: '自动',
          2: '手动',
        },
        total: 0,
        listQuery: Object.assign({},listQuery),
        initListQuery: Object.assign({},listQuery),
        tableData: [],
      }
    },
    created() {
      getBtnPerms((btnPerms) => {
        this.savePerm = verifyBtnPerm(btnPerms,'data-find-2')
          this.updatePerm = verifyBtnPerm(btnPerms,'data-find-3')
          this.delPerm = verifyBtnPerm(btnPerms,'data-find-4')
          this.runPerm = verifyBtnPerm(btnPerms,'data-find-5')
          this.readPerm = verifyBtnPerm(btnPerms,'data-find-6')
      })
      const cacheQuery = this.$store.state.pageQuery.listQuery4
      this.listQuery = Object.assign({},cacheQuery)
      this.getList()
    },
    methods: {
      updateDataFindStatus(id, status){
        const msg = status === 1 ? '停止执行' : '立即执行'
        // const statusCopy = status === 2 ? 2 : 1
        UpdateDataFindStatus({ id: id, status: status }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.getList()
          } else {
            error(resData.msg)
          }
        })
        // const msg = status === 1 ? '停止执行' : '立即执行'
        // const statusCopy = status === 1 ? 2 : 1
        // this.$confirm(`是否确认${msg}`, '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        //   closeOnClickModal: false,
        // }).then(() => {
        //   UpdateDataFindStatus({ id: id, status: statusCopy }).then(res => {
        //     const resData = res.data
        //     if (resData.code === 0) {
        //       success(resData.msg)
        //       this.getList()
        //     } else {
        //       error(resData.msg)
        //     }
        //   })
        // })
      },
      del(row) {
        this.$confirm('是否确认删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          const ids = []
          ids.push(row.id)
          DeleteDataFindByIds({ ids }).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
        })
      },
      toDetail(row){
        this.$router.push({
          path: '/dataFoundDetail',
          query: {
            id: row.id
          }
        })
      },
      saveDialogShow(type, row) {
        if(type === 2){
          this.$router.push({
            path: '/dataFoundSave',
            query: {
              id: row.id
            }
          })
        }else{
          this.$router.push({
            path: '/dataFoundSave'
          })
        }

      },
      getList() {
        this.loading = true
        GetDataFindListByPage(this.listQuery).then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        }).catch(() => {
          this.loading = false
        })
      },
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
    },

  }
</script>

<style scoped>

</style>
