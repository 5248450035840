<template>
  <b-row class="match-height">
    <b-col cols="12">
      <InfoAssestTotal />
    </b-col>

    <!-- 个人信息资产趋势  -->
    <b-col cols="9">
      <b-card no-body>
        <b-card-header>
          <el-col :span="12"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息资产趋势</span>
          </el-col>
          <div class="d-flex align-items-center">
            <el-date-picker v-model="rangePicker"
                            type="daterange"
                            placement="bottom-start"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            @change="dateChange"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </b-card-header>

        <b-card-body v-loading="totalLineLoading">
          <div v-show="FieldsChartData.totalLine.series[0].data.length>0"
               sytle="height:240px;">
            <vue-apex-charts v-if="totalLineInit"
                             type="line"
                             height="240"
                             :options="FieldsChartData.totalLine.chartOptions"
                             :series="FieldsChartData.totalLine.series" />
          </div>
          <el-empty v-show="FieldsChartData.totalLine.series[0].data.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>

    <!-- 个人信息资产 按分级统计  -->
    <b-col cols="3">
      <b-card no-body>
        <b-card-header>
          <el-col :span="24"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息敏感性占比</span>
          </el-col>
        </b-card-header>

        <b-card-body v-loading="sensitiveSatisfactionLoading">
          <div v-show="FieldsChartData.classChart.series.length > 0">
            <vue-apex-charts v-if="sensitiveSatisfactionInit"
                             type="pie"
                             height="260"
                             class="mt-0 mb-0"
                             :options="FieldsChartData.classChart.chartOptions"
                             :series="FieldsChartData.classChart.series" />
          </div>
          <el-empty v-show="FieldsChartData.classChart.series.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>

    <!-- 个人信息字段数 Top15 统计  -->
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <el-col :span="12"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息字段Top15</span>
          </el-col>
        </b-card-header>

        <b-card-body v-loading="fieldTypeLoading">
          <div v-show="FieldsChartData.fieldsChart.series[0].data.length>0">
            <vue-apex-charts v-if="fieldTypeInit"
                             type="bar"
                             height="280"
                             :options="FieldsChartData.fieldsChart.chartOptions"
                             :series="FieldsChartData.fieldsChart.series" />
          </div>
          <el-empty v-show="FieldsChartData.fieldsChart.series[0].data.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>

    <!-- 个人信息资产 按分类的分布图  -->
    <b-col md="6">
      <b-card no-body>
        <b-card-header>
          <el-col :span="20"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息分类Top10</span>
          </el-col>
        </b-card-header>

        <b-card-body v-loading="classChartLoading">
          <div v-show="FieldsChartData.levelChart.series[0].data.length > 0">
            <vue-apex-charts v-if="classChartInit"
                             type="bar"
                             height="280"
                             :options="FieldsChartData.levelChart.chartOptions"
                             :series="FieldsChartData.levelChart.series" />
          </div>
          <el-empty v-show="FieldsChartData.levelChart.series[0].data.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>
    <!-- 个人信息资产Top10 -->
    <b-col md="6">
      <b-card no-body>
        <b-card-header>
          <el-col :span="20"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息资产Top10</span>
          </el-col>
        </b-card-header>
        <b-card-body v-loading="assestChartLoading">
          <div v-show="FieldsChartData.assetChart.series[0].data.length > 0">
            <vue-apex-charts v-if="assestChartInit"
                             type="bar"
                             height="260"
                             :options="FieldsChartData.assetChart.chartOptions"
                             :series="FieldsChartData.assetChart.series" />
          </div>
          <el-empty v-show="FieldsChartData.assetChart.series[0].data.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>
    <!--    个人信息数量IT资产Top5-->
    <b-col md="6">
      <b-card no-body>
        <b-card-header>
          <el-col :span="20"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息数量IT资产Top5</span>
          </el-col>
        </b-card-header>

        <b-card-body v-loading="assetsFieldChartLoading">
          <div v-show="FieldsChartData.infoAssestTopChart.series[0].data.length > 0">
            <vue-apex-charts v-if="assetsFieldChartInit"
                             type="bar"
                             height="280"
                             :options="FieldsChartData.infoAssestTopChart.chartOptions"
                             :series="FieldsChartData.infoAssestTopChart.series" />
          </div>
          <el-empty v-show="FieldsChartData.infoAssestTopChart.series[0].data.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>

    <!-- 个人信息资产 按标签的分布图，用数量，不用比例  -->
    <b-col md="6">
      <b-card no-body>
        <b-card-header>
          <el-col :span="20"
                  class="font-medium-1 mt-10">
            <span class="title">个人信息标签</span>
          </el-col>
        </b-card-header>
        <b-card-body v-loading="tagChartLoading">
          <div v-show="FieldsChartData.tagChart.series.length > 0">
            <vue-apex-charts v-if="tagChartInit"
                             type="donut"
                             height="280"
                             :options="FieldsChartData.tagChart.chartOptions"
                             :series="FieldsChartData.tagChart.series" />
          </div>

          <el-empty v-show="FieldsChartData.tagChart.series.length == 0"
                    description="暂无数据"
                    size="100" />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BLink, BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle, } from 'bootstrap-vue'

import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import FieldsChartData from '../fields/FieldsChartData'
import { $themeColors } from '@themeConfig'
import { GetSaveCountByMouth, GetFieldSatisfaction, GetSensitiveSatisfaction, GetClassSatisfaction, GetAssetsTypeSatisfaction, GetLabelSatisfaction,GetAssetsFieldSatisfaction } from '@/api/dataMap/dataMapProcess'
import InfoAssestTotal from './InfoAssestTotal.vue'
import Moment from 'moment'
export default {
  components: {
    BRow,
    BCol,
    BLink,

    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    InfoAssestTotal,
  },

  data () {
    const beginTime = Moment().subtract(6, 'months').format("YYYY-MM-DD")
    const endTime = Moment().format("YYYY-MM-DD")
    return {
      FieldsChartData,
      rangePicker: [beginTime, endTime],
      fieldTypeLoading: false,
      fieldTypeInit: true,
      sensitiveSatisfactionLoading: false,
      sensitiveSatisfactionInit: true,
      classChartLoading: false,
      classChartInit: true,
      assestChartLoading: false,
      assestChartInit: true,
      tagChartLoading: false,
      tagChartInit: false,
      totalLineLoading: false,
      totalLineInit: true,
      listQuery: {
        beginTime: beginTime,
        endTime: endTime,
      },
      assetsFieldChartLoading: false,
      assetsFieldChartInit: false
    }
  },
  created () {
    this.getSaveCountByMouth()
    this.getFieldSatisfaction()
    this.getSensitiveSatisfaction()
    this.getClassSatisfaction()
    this.getAssetsTypeSatisfaction()
    this.getLabelSatisfaction()
    this.getAssetsFieldSatisfaction()
  },
  methods: {
    dateChange () {
      if (this.rangePicker) {
        this.listQuery.beginTime = this.rangePicker[0]
        this.listQuery.endTime = this.rangePicker[1]
      } else {
        this.listQuery.beginTime = ''
        this.listQuery.endTime = ''
      }
      this.getSaveCountByMouth()
    },
    getSaveCountByMouth () {
      this.totalLineLoading = true
      this.totalLineInit = false
      this.FieldsChartData.totalLine.series[0].data = []
      this.FieldsChartData.totalLine.chartOptions.xaxis.categories = []
      GetSaveCountByMouth(this.listQuery).then(res => {
        this.totalLineLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          chartData.forEach(r => {
            this.FieldsChartData.totalLine.series[0].data.push(r.saveCount)
            this.FieldsChartData.totalLine.chartOptions.xaxis.categories.push(r.mouth)
          })
          this.totalLineInit = true
        }
      })
    },
    // 个人信息字段Top10
    getFieldSatisfaction () {
      this.fieldTypeLoading = true
      this.fieldTypeInit = false
      this.FieldsChartData.fieldsChart.series[0].data = []
      this.FieldsChartData.fieldsChart.chartOptions.xaxis.categories = []
      GetFieldSatisfaction().then(res => {
        this.fieldTypeLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          chartData.forEach(r => {
            this.FieldsChartData.fieldsChart.series[0].data.push(r.saveCount)
            this.FieldsChartData.fieldsChart.chartOptions.xaxis.categories.push(r.name)
          })
          this.fieldTypeInit = true
        }
      })
    },
    // 个人信息类型饼图
    getSensitiveSatisfaction () {
      this.sensitiveSatisfactionLoading = true
      this.sensitiveSatisfactionInit = false
      this.FieldsChartData.classChart.series = []
      GetSensitiveSatisfaction().then(res => {
        this.sensitiveSatisfactionLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          this.FieldsChartData.classChart.series.push(chartData.generalSaveCount)
          this.FieldsChartData.classChart.series.push(chartData.sensitiveSaveCount)
          this.sensitiveSatisfactionInit = true
        }
      })
    },
    // 个人信息分类Top10
    getClassSatisfaction () {
      this.classChartLoading = true
      this.classChartInit = false
      this.FieldsChartData.levelChart.series[0].data = []
      this.FieldsChartData.levelChart.chartOptions.xaxis.categories = []
      GetClassSatisfaction().then(res => {
        this.classChartLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          chartData.forEach(r => {
            this.FieldsChartData.levelChart.series[0].data.push(r.saveCount)
            this.FieldsChartData.levelChart.chartOptions.xaxis.categories.push(r.name)
          })
          this.classChartInit = true
        }
      })
    },
    // 个人信息资产top10
    getAssetsTypeSatisfaction () {
      this.assestChartLoading = true
      this.assestChartInit = false
      this.FieldsChartData.assetChart.series[0].data = []
      this.FieldsChartData.assetChart.chartOptions.xaxis.categories = []
      GetAssetsTypeSatisfaction().then(res => {
        this.assestChartLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          chartData.forEach(r => {
            this.FieldsChartData.assetChart.series[0].data.push(r.saveCount)
            this.FieldsChartData.assetChart.chartOptions.xaxis.categories.push(r.name)
          })
          this.assestChartInit = true
        }
      })
    },
    // 个人信息标签饼图
    getLabelSatisfaction () {
      this.tagChartLoading = true
      this.tagChartInit = false
      this.FieldsChartData.tagChart.series = []
      this.FieldsChartData.tagChart.chartOptions.labels = []
      GetLabelSatisfaction().then(res => {
        this.tagChartLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          chartData.forEach(c => {
            this.FieldsChartData.tagChart.series.push(c.saveCount)
            this.FieldsChartData.tagChart.chartOptions.labels.push(c.name)
          })
          this.tagChartInit = true
        }
      })
    },
    // 个人信息数量资产top5
    getAssetsFieldSatisfaction() {
      this.FieldsChartData.infoAssestTopChart.series[0].data = []
      this.FieldsChartData.infoAssestTopChart.chartOptions.xaxis.categories = []
      GetAssetsFieldSatisfaction(5).then(res => {
        this.assetsFieldChartLoading = false
        if (res.data.code === 0) {
          const chartData = res.data.data
          chartData.forEach(r => {
            this.FieldsChartData.infoAssestTopChart.series[0].data.push(r.saveCount)
            this.FieldsChartData.infoAssestTopChart.chartOptions.xaxis.categories.push(r.name)
          })
          this.assetsFieldChartInit = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
/*title*/
.title {
  border-left: solid 6px #0d40b2;
  padding-left: 8px;
  margin-bottom: 2px;
}

.el-empty {
  padding: 10px;
}

.card-body {
  padding: 10px;
}
</style>
