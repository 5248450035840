<template>
  <div>
    <PageIntroduce :title="'个人信息资产'"
                   :desc="'管理个人信息字段相关的业务系统和分布。'" />
    <el-tabs v-model="activeName"
             type="border-card">
      <el-tab-pane v-for="(item,index) in navMenus" :label="item.label"
                   :lazy="true"
                   :name="String(index+1)">
        <InfoAssestStatistics v-if="item.label === '数据统计'" />
        <DataFound v-if="item.label === '数据发现'"/>
        <DataList v-if="item.label === '数据清单'"/>
      </el-tab-pane>
      <!--<el-tab-pane label="数据统计"-->
                   <!--:lazy="true"-->
                   <!--name="1">-->
        <!--<InfoAssestStatistics />-->
      <!--</el-tab-pane>-->
      <!--<el-tab-pane label="数据发现"-->
                   <!--:lazy="true"-->
                   <!--name="2">-->
        <!--<DataFound />-->
      <!--</el-tab-pane>-->
      <!--<el-tab-pane label="数据清单"-->
                   <!--:lazy="true"-->
                   <!--name="3">-->
        <!--<DataList />-->
      <!--</el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import DataList from './dataList/DataList.vue'
import InfoAssestStatistics from './infoAssestStatistics/InfoAssestStatistics'
import DataFound from './dataFound/DataFound'
import { enterpriseEvent } from '@core/utils/utils'
export default {
  components: {
    InfoAssestStatistics,
    DataList,
    PageIntroduce,
    DataFound,
  },
  created () {
    const twoLevelMenus = localStorage.getItem('twoLevelMenus')
    if(twoLevelMenus){
      const menus = JSON.parse(twoLevelMenus)
      menus.forEach(d=>{
        if(this.fullMenus.indexOf(d.name) !== -1){
          this.navMenus.push({
            label: d.name,
          })
        }
      })
    }
    this.activeName = sessionStorage.getItem('activeName') || '1'
  },
  data () {
    return {
      activeName: '1',
      navMenus: [],
      fullMenus: ['数据统计','数据发现','数据清单'],
    }
  },
  watch: {
    activeName () {
      sessionStorage.setItem('activeName', this.activeName)
    }
  },
  beforeRouteLeave (to, from, next) {
    sessionStorage.setItem('activeName', '1')
    next()
  }
}
</script>


