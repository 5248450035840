import { get, post } from '@/libs/axios'

export const GetAssetsPrivacyFieldSatisfaction = () => get('/workbench/GetAssetsPrivacyFieldSatisfaction')
export const GetComplianceSatisfaction = () => get('/workbench/GetComplianceSatisfaction')
export const AssessFinishStatistics = assessCode => get(`/assess/${assessCode}/finish_statistics`)
export const GetGapAssessSatisfaction = () => get('/workbench/GetGapAssessSatisfaction')
export const GetPiaSatisfaction = () => get('/workbench/GetPiaSatisfaction')
export const GetRiskCountByMouth = () => get('/workbench/GetRiskCountByMouth')
export const GetProductTotal = () => get('/workbench/GetProductTotal')
export const GetAssetsSatisfaction = () => get('/workbench/GetAssetsSatisfaction')
export const GetCookieSatisfaction = () => get('/workbench/GetCookieSatisfaction')
export const GetChannelSatisfaction = () => get('/workbench/GetChannelSatisfaction')
export const GetFieldSatisfaction = () => get('/workbench/GetFieldSatisfaction')
export const GetStatementReleaseSatisfaction = () => get('/workbench/GetStatementReleaseSatisfaction')
export const GetClassTree = () => get('/workbench/GetClassTree')
export const GetPiaAssessInfoList = () => post('/workbench/GetPiaAssessInfoList')
