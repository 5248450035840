<template>
  <div>
    <div class="flex-between filter-wrap">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.SearchStr"
          width="180"
          placeholder="请输入内容"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <div class="text-right">
        <el-button
          v-if="importPerm"
          size="small"
          type="primary"
          icon="el-icon-upload"
          @click="importDialogShow"
        >
          导入
        </el-button>
        <el-button
          v-if="exportPerm"
          class="mr10"
          size="small"
          type="primary"
          icon="el-icon-download"
          :loading="exportLoading"
          @click="exportPrivacyField"
        >
          导出
        </el-button>
        <el-button
          v-show="savePerm"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="saveDialogShow(1)"
        >
          新增
        </el-button>
      </div>

    </div>
    <FilterSelectList
      :list-query.sync="listQuery"
      :select-list.sync="selectList"
      @handleFilter="handleFilter"
    />
    <el-table
      v-loading="loading"
      fit
      highlight-current-row
      :max-height="pageH - 250"
      border
      :data="tableData"
      stripe
      style="width: 100%"
      @sort-change="sortChange"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        prop="fieldName"
        label="字段名称"
      />
      <el-table-column
        :show-overflow-tooltip="true"
        width="80"
        prop="sensitive"
        label="是否敏感"
      >
        <template v-slot:="scope">
          <el-tag v-if="scope.row.sensitive" size="medium">是</el-tag>
          <el-tag v-if="!scope.row.sensitive" size="medium">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="className"
        label="字段类型"
        :show-overflow-tooltip="true"
      />
      <el-table-column
          prop="productName"
          label="业务名称"
          :show-overflow-tooltip="true"
      />
      <el-table-column
        sortable
        :show-overflow-tooltip="true"
        prop="assetsName"
        label="资产名称"
      >
        <template
          slot="header"
          slot-scope="scope"
        >
          <TableHeaderFilterDropdown
            :key-str="'assetsId'"
            :select-list.sync="selectList"
            :label="'资产名称'"
            :value.sync="listQuery.assetsId"
            :dropdown-options="assetsOptions"
            @handleFilter="handleFilter"
          />
        </template>
        <template slot-scope="{row}">
          {{ row.assetsName }}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        :show-overflow-tooltip="true"
        prop="saveDatabase"
        label="数据库名"
      >
        <template slot="header">
          <TableHeaderFilterDropdown
            :key-str="'saveDatabase'"
            :select-list.sync="selectList"
            :label="'数据库名'"
            :value.sync="listQuery.saveDatabase"
            :dropdown-options="databaseOptions"
            @handleFilter="handleFilter"
          />
        </template>
      </el-table-column>
      <el-table-column
        sortable
        :show-overflow-tooltip="true"
        prop="saveTable"
        label="表名"
      >
        <template slot="header">
          <TableHeaderFilterDropdown
            :key-str="'saveTable'"
            :select-list.sync="selectList"
            :label="'表名'"
            :value.sync="listQuery.saveTable"
            :dropdown-options="tableOptions"
            @handleFilter="handleFilter"
          />
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        prop="saveTableField"
        label="表字段名称"
      />
      <el-table-column
        prop="saveCount"
        label="数据量大约(条)"
      >
        <template slot-scope="{row}">
          <div
            v-show="!row.countCheck"
            @dblclick="countClick(row)"
          >{{ row.saveCount }}</div>
          <div
            v-show="row.countCheck"
            class="flex-between"
          >
            <el-input
              v-model="row.saveCount1"
              maxlength="15"
              oninput="value=value.replace(/[^0-9]/g, '')"
            />
            <div style="width: 60px;font-size: 20px;cursor: pointer;">
              <i
                class="el-icon-circle-close"
                @click="countSave(0,row)"
              />
              <i
                style="color: #0d40b2;"
                class="el-icon-circle-check"
                @click="countSave(1,row)"
              />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="80px"
      >
        <template slot-scope="{row}">
          <el-link
            v-show="updatePerm"
            :underline="false"
            class="mr10"
            type="primary"
            @click="saveDialogShow(2,row)"
          >
            <el-tooltip
              content="修改"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            v-show="delPerm"
            :underline="false"
            type="primary"
            @click="del(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <!--<pagination v-show="total>0"-->
    <!--:total="total"-->
    <!--:page.sync="listQuery.page"-->
    <!--:limit.sync="listQuery.pageSize"-->
    <!--@pagination="getList" />-->
    <PageQuery
      v-show="total>0"
      :total="total"
      :cache-name="'listQuery5'"
      :update-state="'UPDATE_LISTQUERY5'"
      :list-query.sync="listQuery"
      :init-list-query.sync="initListQuery"
      @pagination="getList"
    />
    <ImportDataList
      ref="importPricacyFields"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
// eslint-disable-next-line import/extensions
import {
  GetAssetsPrivacyFieldListByPage, DeleteAssetsPrivacyFieldByIds, UpdateSaveCount, GetAllDatabaseName, GetAllTableName, ExportPrivacyField,
} from '@/api/dataMap/dataMapProcess'
import { QueryAssetsOptions } from '@/api/enterprise/assest'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import ImportDataList from './ImportDataList.vue'

export default {
  components: {
    pagination,
    ImportDataList,
    TableHeaderFilterDropdown,
    FilterSelectList,
  },

  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      assetsId: 0,
      saveDatabase: '',
      saveTable: '',
      orderBy: {
        assetsName: '',
        saveDatabase: '',
        saveTable: '',
      },
    }
    return {
      savePerm: findBtnPerm('data-list-2'),
      updatePerm: findBtnPerm('data-list-3'),
      delPerm: findBtnPerm('data-list-4'),
      importPerm: findBtnPerm('data-list-5'),
      exportPerm: findBtnPerm('data-list-6'),
      detailShow: false,
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      column1Arr: [], // column1
      column1Index: 0, // column1索引
      column2Arr: [], // column2
      column2Index: 0, // column2索引
      list: [],
      spanArr1: [], // 用于存放每一行记录的合并数
      pos1: 0,
      spanArr2: [], // 用于存放每一行记录的合并数
      pos2: 0,
      selectList: [],
      assetsOptions: [],
      databaseOptions: [],
      tableOptions: [],
      exportLoading: false,
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'data-list-2')
      this.updatePerm = verifyBtnPerm(btnPerms, 'data-list-3')
      this.delPerm = verifyBtnPerm(btnPerms, 'data-list-4')
      this.importPerm = verifyBtnPerm(btnPerms, 'data-list-5')
      this.exportPerm = verifyBtnPerm(btnPerms, 'data-list-6')
    })
    const cacheQuery = this.$store.state.pageQuery.listQuery5
    this.listQuery = { ...cacheQuery }
    QueryAssetsOptions(0, 0, '').then(res => {
      const resData = res.data
      if (resData.code === 0) {
        resData.data.forEach(assets => {
          this.assetsOptions.push({
            label: assets.name,
            value: assets.id,
          })
        })
      } else {
        error(resData.msg)
      }
    })
    GetAllDatabaseName().then(res => {
      const resData = res.data
      if (resData.code === 0) {
        resData.data.forEach(database => {
          this.databaseOptions.push({
            label: database,
            value: database,
          })
        })
      } else {
        error(resData.msg)
      }
    })
    GetAllTableName().then(res => {
      const resData = res.data
      if (resData.code === 0) {
        resData.data.forEach(table => {
          this.tableOptions.push({
            label: table,
            value: table,
          })
        })
      } else {
        error(resData.msg)
      }
    })
    this.getList()
  },
  methods: {
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'desc', ascending: 'asc' }
      this.listQuery.orderBy[column.prop] = orderObj[order]
      this.getList()
    },
    countSave(type, row) {
      if (type && row.saveCount1) {
        UpdateSaveCount({
          id: row.id,
          saveCount: Number(row.saveCount1),
        }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.$set(row, 'saveCount', row.saveCount1)
          } else {
            error(resData.msg)
          }
        })
      }
      this.$set(row, 'countCheck', false)
    },
    countClick(row) {
      this.$set(row, 'saveCount1', row.saveCount)
      this.$set(row, 'countCheck', true)
    },
    importDialogShow() {
      this.$refs.importPricacyFields.dialogVisible = true
    },
    del(assestId) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const ids = []
        ids.push(assestId)
        DeleteAssetsPrivacyFieldByIds({ ids }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getSpanArr(data) {
      // data就是我们从后台拿到的数据
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr1.push(1)
          this.pos1 = 0
          this.spanArr2.push(1)
          this.pos2 = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].fieldId === data[i - 1].fieldId) {
            this.spanArr1[this.pos1] += 1
            this.spanArr1.push(0)
          } else {
            this.spanArr1.push(1)
            this.pos1 = i
          }
          if (data[i].fieldId === data[i - 1].fieldId && data[i].assetsName === data[i - 1].assetsName) {
            this.spanArr2[this.pos2] += 1
            this.spanArr2.push(0)
          } else {
            this.spanArr2.push(1)
            this.pos2 = i
          }
        }
      }
    },
    toDataMapTracker(row) {
      this.$router.push({ path: '/datamap-tracker', query: { id: row.id } })
    },
    getIndex($index) {
      return (this.listQuery.page - 1) * this.listQuery.pageSize + $index + 1
    },
    detailDialogShow(row) {
      this.detailShow = true
    },
    saveDialogShow(type, row) {
      if (type === 1) {
        this.$router.push({
          path: '/dataListSave',
        })
      } else {
        this.$router.push({
          path: '/dataListSave',
          query: {
            id: row.id,
          },
        })
      }

      // this.$refs.privacyFieldsEdit.dialogVisible = true
    },
    getList() {
      this.loading = true
      GetAssetsPrivacyFieldListByPage(this.listQuery).then(res => {
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      })
      this.loading = false
      // .catch(() => {
      //   this.loading = false
      // })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.listQuery.assetsId = Number(this.listQuery.assetsId)
      this.getList()
    },
    exportPrivacyField() {
      this.exportLoading = true
      ExportPrivacyField().then(() => {
        this.exportLoading = false
      })
    },
  },
}
</script>
<style>
.cell .el-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  width: 100%;
}
</style>
