import { $themeColors } from '@themeConfig'

// heat chart data generator
function generateDataHeatMap(count, yrange) {
  let i = 0
  const series = []
  while (i < count) {
    const x = `w${(i + 1).toString()}`
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

    series.push({
      x,
      y,
    })
    // eslint-disable-next-line no-plusplus
    i++
  }
  return series
}

// colors
const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    series3: '#ffe700',
    series4: '#2b9bf4',
    bg: '#FFA1A1',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },

  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {

  fieldsChart: {
    series: [
      {
        name: '',
        // data: [700, 350, 480, 600, 210, 550, 150, 210, 550, 150],
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: '#03C6FE',
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: '30%',
          columnWidth: '30%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top',
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          fontWeight: 'null',
          colors: ['#6e6b7b'],
        },
      },
      xaxis: {
        // categories: ['姓名', '性别', '身份证', '国籍', '籍贯', '地理位置', '电话号码', '出生日期', '护照', '社保卡'],
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  lineChartSimple: {
    series: [
      {
        data: [280, 200, 220, 180, 270, 250, 70, 90, 200, 150, 160, 100, 150, 100, 50],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.warning],
      },
      colors: [$themeColors.warning],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${
            data.series[data.seriesIndex][data.dataPointIndex]
          }%</span></div>`
        },
      },
      xaxis: {
        categories: [
          '7/2022',
          '8/2022',
          '9/2022',
          '10/2022',
          '11/2022',
          '12/2022',
          '01/2023',
        ],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  columnChart: {
    series: [
      {
        name: '个人自然信息',
        data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
      },
      {
        name: '身份鉴别信息',
        data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
      },
      {
        name: '通用网络活动信息',
        data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series1, chartColors.column.series2, chartColors.column.series3],
      plotOptions: {
        bar: {
          columnWidth: '30%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '12px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12'],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },

  lineAreaChartSpline: {
    series: [
      {
        name: '业务系统1',
        data: [100, 120, 90, 170, 130, 160],
      },
      {
        name: '业务系统2',
        data: [60, 80, 70, 110, 80, 100],
      },
      {
        name: '业务系统3',
        data: [20, 40, 30, 70, 40, 60],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        curve: 'straight',
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
        fontSize: '12px',
        fontFamily: 'Montserrat',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: [
          '8/2022',
          '9/2022',
          '10/2022',
          '11/2022',
          '12/2022',
          '01/2023',
        ],
      },
      yaxis: {
        // opposite: isRtl
      },
      fill: {
        opacity: 1,
        type: 'solid',
      },
      tooltip: {
        shared: false,
      },
      colors: [chartColors.area.series3, chartColors.area.series2, chartColors.area.series1],
    },
  },

  assetChart: {
    series: [
      {
        name: '个人资产Top10',
        // data: [700, 350, 480, 600, 210, ],
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: $themeColors.info,
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30%',
          endingShape: 'rounded',
          dataLabels: {
            position: 'top',
          },
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: 50,
        style: {
          fontSize: '12px',
          fontWeight: 'null',
          colors: ['#6e6b7b'],
        },
      },
      xaxis: {
        // categories: ['资产-前台', '资产-后台', '资产-数据库', '资产-服务器', '其他'],
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  candlestickChart: {
    series: [
      {
        data: [
          {
            x: new Date(1538778600000),
            y: [150, 170, 50, 100],
          },
          {
            x: new Date(1538780400000),
            y: [200, 400, 170, 330],
          },
          {
            x: new Date(1538782200000),
            y: [330, 340, 250, 280],
          },
          {
            x: new Date(1538784000000),
            y: [300, 330, 200, 320],
          },
          {
            x: new Date(1538785800000),
            y: [320, 450, 280, 350],
          },
          {
            x: new Date(1538787600000),
            y: [300, 350, 80, 250],
          },
          {
            x: new Date(1538789400000),
            y: [200, 330, 170, 300],
          },
          {
            x: new Date(1538791200000),
            y: [200, 220, 70, 130],
          },
          {
            x: new Date(1538793000000),
            y: [220, 270, 180, 250],
          },
          {
            x: new Date(1538794800000),
            y: [200, 250, 80, 100],
          },
          {
            x: new Date(1538796600000),
            y: [150, 170, 50, 120],
          },
          {
            x: new Date(1538798400000),
            y: [110, 450, 10, 420],
          },
          {
            x: new Date(1538800200000),
            y: [400, 480, 300, 320],
          },
          {
            x: new Date(1538802000000),
            y: [380, 480, 350, 450],
          },
        ],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
        // opposite: isRtl,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: $themeColors.success,
            downward: $themeColors.danger,
          },
        },
        bar: {
          columnWidth: '40%',
        },
      },
    },
  },

  scatterChart: {
    series: [
      {
        name: 'Angular',
        data: [
          [5.4, 170],
          [5.4, 100],
          [6.3, 170],
          [5.7, 140],
          [5.9, 130],
          [7.0, 150],
          [8.0, 120],
          [9.0, 170],
          [10.0, 190],
          [11.0, 220],
          [12.0, 170],
          [13.0, 230],
        ],
      },
      {
        name: 'Vue',
        data: [
          [14.0, 220],
          [15.0, 280],
          [16.0, 230],
          [18.0, 320],
          [17.5, 280],
          [19.0, 250],
          [20.0, 350],
          [20.5, 320],
          [20.0, 320],
          [19.0, 280],
          [17.0, 280],
          [22.0, 300],
          [18.0, 120],
        ],
      },
      {
        name: 'React',
        data: [
          [14.0, 290],
          [13.0, 190],
          [20.0, 220],
          [21.0, 350],
          [21.5, 290],
          [22.0, 220],
          [23.0, 140],
          [19.0, 400],
          [20.0, 200],
          [22.0, 90],
          [20.0, 120],
        ],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: true,
          type: 'xy',
        },
        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: [$themeColors.warning, $themeColors.primary, $themeColors.success],
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter(val) {
            return parseFloat(val).toFixed(1)
          },
        },
      },
      yaxis: {
        // opposite: isRtl,
      },
    },
  },

  heatMapChart: {
    series: [
      {
        name: '前端应用服务器',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
      {
        name: '后端应用服务器',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
      {
        name: '中间件',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
      {
        name: '数据库',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
      {
        name: '文件存储',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
      {
        name: '数据备份',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
      {
        name: '其他',
        data: generateDataHeatMap(24, {
          min: 0,
          max: 60,
        }),
      },
    ],
    chartOptions: {
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'bottom',
      },
      colors: ['#7367F0'],
      plotOptions: {
        heatmap: {
          enableShades: false,

          colorScale: {
            ranges: [
              {
                from: 0,
                to: 10,
                name: '0-10',
                color: '#b9b3f8',
              },
              {
                from: 11,
                to: 20,
                name: '10-20',
                color: '#aba4f6',
              },
              {
                from: 21,
                to: 30,
                name: '20-30',
                color: '#9d95f5',
              },
              {
                from: 31,
                to: 40,
                name: '30-40',
                color: '#8f85f3',
              },
              {
                from: 41,
                to: 50,
                name: '40-50',
                color: '#8176f2',
              },
              {
                from: 51,
                to: 60,
                name: '50-60',
                color: '#7367f0',
              },
            ],
          },
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      chart: {
        height: 350,
        type: 'heatmap',
        toolbar: {
          show: false,
        },
      },
    },
  },

  tagChart: {
    // series: [85, 16, 50, 50, 50],
    series: [],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
        chartColors.donut.series4,
        chartColors.donut.series5,
        chartColors.donut.series3,
        chartColors.donut.series2,
        chartColors.donut.series1,
      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}`
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: '总计',
              },
            },
          },
        },
      },
      // labels: ['标签1', '标签2', '标签3', '标签4', '标签5'],
      labels: [],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },

  radialBarChart: {
    series: [80, 50, 35],
    chartOptions: {
      colors: [chartColors.donut.series1, chartColors.donut.series2, chartColors.donut.series4],
      plotOptions: {
        radialBar: {
          size: 185,
          hollow: {
            size: '25%',
          },
          track: {
            margin: 15,
          },
          dataLabels: {
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
            },
            total: {
              show: true,
              fontSize: '1rem',
              label: 'Comments',
              formatter() {
                return '80%'
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Comments', 'Replies', 'Shares'],
    },
  },

  radarChart: {
    series: [
      {
        name: 'iPhone 11',
        data: [41, 64, 81, 60, 42, 42, 33, 23],
      },
      {
        name: 'Samsung s20',
        data: [65, 46, 42, 25, 58, 63, 76, 43],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          blur: 8,
          left: 1,
          top: 1,
          opacity: 0.2,
        },
      },
      legend: {
        show: true,
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      yaxis: {
        show: false,
      },
      colors: [chartColors.donut.series1, chartColors.donut.series3],
      xaxis: {
        categories: ['Battery', 'Brand', 'Camera', 'Memory', 'Storage', 'Display', 'OS', 'Price'],
      },
      fill: {
        opacity: [1, 0.8],
      },
      stroke: {
        show: false,
        width: 0,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: false,
      },
    },
  },

  totalLine: {
    series: [
      {
        name: '个人信息数量',
        // data: [140, 180, 150, 205, 160, 295, 125, 255,],
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        type: 'line',
        dropShadow: {
          enabled: true,
          top: 18,
          left: 2,
          blur: 5,
          opacity: 0.2,
        },
        offsetX: -10,
      },
      stroke: {
        curve: 'smooth',
        width: 4,
      },
      dataLabels: {
        enabled: true,
        offsetY: -10,
        background: {
          enabled: true,
          padding: 4,
          borderRadius: 2,
          borderColor: '#fff',
          opacity: 0.5,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45,
          },
        },
      },
      grid: {
        borderColor: '#ebe9f1',
        padding: {
          top: 20,
          bottom: 5,
          left: 20,
        },
      },
      legend: {
        show: false,
      },
      colors: ['#df87f2'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          inverseColors: false,
          gradientToColors: [$themeColors.primary],
          shadeIntensity: 1,
          type: 'horizontal',
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          offsetY: 5,
          style: {
            colors: '#b9b9c3',
            fontSize: '0.857rem',
          },
        },
        axisTicks: {
          show: false,
        },
        // categories: ['2022/06', '2022/07', '2022/08', '2022/09', '2022/10', '2022/11', '2022/12', '2023/01', ],
        categories: [],
        axisBorder: {
          show: false,
        },
        tickPlacement: 'on',
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {
            colors: '#b9b9c3',
            fontSize: '0.857rem',
          },
          formatter(val) {
            return val
            // return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
          },
        },
      },
      tooltip: {
        x: { show: false },
      },
    },
  },

  latestBarChart: {
    data: {
      labels: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12'],
      datasets: [
        {
          data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 230, 280, 190],
          backgroundColor: chartColors.successColorShade,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: false,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              stepSize: 100,
              min: 0,
              max: 400,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
  },

  classChart: {
    // series: [690, 258, ],
    series: [],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      labels: ['一般个人信息', '敏感个人信息'],
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      stroke: {
        width: 3,
      },
      colors: [$themeColors.primary, $themeColors.danger],
    },
  },

  levelChart: {
    series: [
      {
        name: '个人信息分类',
        // data: [90, 120, 55, 100, 80, 125, 175, 56, 190, 99, ],
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series4],
      plotOptions: {
        bar: {
          columnWidth: '20%',
          endingShape: 'rounded',
          colors: {
            backgroundBarColors: [
              // chartColors.column.bg,
              // chartColors.column.bg,
              // chartColors.column.bg,
              // chartColors.column.bg,
              // chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          fontWeight: 'null',
          colors: ['#6e6b7b'],
        },
      },
      legend: {
        show: false,
        position: 'top',
        fontSize: '12px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        // categories: ['个人通用信息', '个人自然信息', '个人基本信息', '个人身份信息', '个人联系信息', '个人基本生理信息', '个人教育信息', '个个人工作信息', '个人资质证书信息', '个人财产信息', ],
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
  infoAssestTopChart: {
    series: [
      {
        name: '个人信息分类',
        // data: [90, 120, 55, 100, 80, 125, 175, 56, 190, 99, ],
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.series4],
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '20%',
          endingShape: 'rounded',
          colors: {
            backgroundBarRadius: 10,
          },
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          fontWeight: 'null',
          colors: ['#6e6b7b'],
        },
      },
      legend: {
        show: false,
        position: 'top',
        fontSize: '12px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        // categories: ['个人通用信息', '个人自然信息', '个人基本信息', '个人身份信息', '个人联系信息', '个人基本生理信息', '个人教育信息', '个个人工作信息', '个人资质证书信息', '个人财产信息', ],
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
}
